<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <h5>기준값 설정</h5>
        <span>조회기준시 : {{ searchTime }}</span>
      </div>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th colspan="2">이름</th>
              <th>현재값</th>
              <th>최소값</th>
              <th>최대값</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-show="key"
              v-for="item in filteredPlcSensorLists"
              :key="item.id"
            >
              <td>
                {{
                  `${
                    findInfoFromId(plc_locations, item.plc_location_id).name
                  }-${
                    findInfoFromId(plc_sensor_types, item.plc_sensor_type_id)
                      .name
                  }`
                }}
              </td>
              <td>
                {{
                  findInfoFromId(
                    plc_sensor_detail_types,
                    item.plc_sensor_detail_type_id,
                  ).name
                }}
              </td>
              <td>
                {{
                  plc_sensor_current_logs.length > 0 &&
                  plc_sensor_current_logs.find(
                    x => x.plc_sensor_id == item.id,
                  ) != undefined
                    ? plc_sensor_current_logs.find(
                        x => x.plc_sensor_id == item.id,
                      ).value
                    : ' - '
                }}
                {{
                  [1, 3].includes(item.plc_sensor_detail_type_id) ? `℃` : `%`
                }}
              </td>
              <td
                :class="{
                  t_unit: [1, 3].includes(item.plc_sensor_detail_type_id),
                  h_unit: ![1, 3].includes(item.plc_sensor_detail_type_id),
                }"
              >
                <input
                  type="number"
                  placeholder="최소값 입력"
                  :value="item.min_value"
                  @input="minInput($event, item.id)"
                />
                <!-- $event => {
                      item.min_value = $event.target.value;
                    } -->
              </td>
              <td
                :class="{
                  t_unit: [1, 3].includes(item.plc_sensor_detail_type_id),
                  h_unit: ![1, 3].includes(item.plc_sensor_detail_type_id),
                }"
              >
                <input
                  type="number"
                  placeholder="최대값 입력"
                  v-model="item.max_value"
                  @input="maxInput($event, item.id)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="btn_wrap">
        <button class="btn_sub2" @click="submitData">저장</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { yyyy년MM월dd일HH시mm분ss초 } from '@/utils/func';
import FETCH_MIXIN from '@/mixins/fetch';

export default {
  mixins: [FETCH_MIXIN],
  data() {
    return {
      key: false,
    };
  },
  computed: {
    ...mapGetters({
      plc_sensor_logs: 'getPlcSensorLog',
      plc_sensor_lists: 'getPlcSensor',
      plc_locations: 'getSensorLocations',
      plc_sensor_types: 'getSensorTypes',
      plc_sensor_detail_types: 'getSensorDetailTypes',
      plc_sensor_current_logs: 'getPlcSensorCurrentLog',
    }),
    searchTime() {
      let date = null;

      if (
        this.plc_sensor_current_logs != undefined &&
        this.plc_sensor_current_logs.length > 0
      ) {
        date = new Date(this.plc_sensor_current_logs[0].create_time);
      } else {
        date = new Date();
      }
      return yyyy년MM월dd일HH시mm분ss초(date);
    },
    filteredPlcSensorLists() {
      if (this.plc_sensor_lists == undefined) {
        return [];
      } else {
        const TorTH = this.lodash.clonedeep(
          this.plc_sensor_lists.filter(
            x =>
              ['TH', 'T'].includes(
                this.findInfoFromId(this.plc_sensor_types, x.plc_sensor_type_id)
                  .detail,
              ) && !x.name.includes('제습기'),
          ),
        );
        const TempLogs = TorTH.filter(x =>
          [1, 3].includes(x.plc_sensor_detail_type_id),
        );

        const HumiLogs = TorTH.filter(x => x.plc_sensor_detail_type_id == 2);
        return TempLogs.concat(HumiLogs).sort((a, b) => a.id - b.id);
      }
    },
  },
  methods: {
    minInput(e, id) {
      this.plc_sensor_lists.find(x => x.id == id).min_value = e.target.value;
    },
    maxInput(e, id) {
      this.plc_sensor_lists.find(x => x.id == id).max_value = e.target.value;
    },
    async submitData() {
      let clone = this.lodash.clonedeep(this.filteredPlcSensorLists);
      clone.forEach(el => {
        el.min_value = this.$makeNumber(el.min_value);
        el.max_value = this.$makeNumber(el.max_value);
      });

      let payload = {
        data_list: clone,
      };

      this.showSpinner();
      await this.$store
        .dispatch('UPDATE_FETCH_PLC_SENSOR_CURRENT_LOG_TO_PLC_SENSR', payload)
        .then(() => {
          this.openOneButtonModal('수정 성공', '성공적으로 수정하였습니다.');
          this.FETCH('FETCH_PLC_SENSOR', '센서 정보');
        })
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '저장 중 에러',
            '기준값 설정 저장 중 에러발생',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    tableSum() {
      let table = document.querySelector('.mes_tbl');
      let trArr = table.getElementsByTagName('tr');
      for (let tdIdx = 1; tdIdx >= 0; tdIdx--) {
        let rowSpan = 0;
        let compText = '';
        for (let trIdx = 1; trIdx < trArr.length; trIdx++) {
          let td = table.rows[trIdx].cells[tdIdx];
          if (compText == '') {
            compText = td.outerText;
            continue;
          }
          if (compText == td.outerText) {
            rowSpan++;
            td.setAttribute('class', 'del');
          } else {
            let td = table.rows[trIdx - 1 - rowSpan].cells[tdIdx];
            td.setAttribute('rowspan', rowSpan + 1);
            rowSpan = 0;
            compText = table.rows[trIdx].cells[tdIdx].outerText;
          }
          if (trIdx == trArr.length - 1 && rowSpan > 0) {
            let cell = table.rows[trIdx - rowSpan].cells[tdIdx];
            cell.setAttribute('rowspan', rowSpan + 1);
            rowSpan = 0;
            compText = '';
          }
        }
      }
      table = document.querySelector('.mes_tbl');
      let dels = table.getElementsByClassName('del');
      for (let i = dels.length - 1; i >= 0; i--) {
        dels[i].remove();
      }
    },
  },
  async created() {
    await this.FETCH('FETCH_PLC_SENSOR', '센서 정보');
    if (this.plc_locations.length < 1) {
      await this.FETCH('FETCH_PLC_SENSOR_LOCATION', '센서 위치');
    }
    if (this.plc_sensor_types.length < 1) {
      await this.FETCH('FETCH_PLC_SENSOR_TYPE', '센서 유형');
    }
    if (this.plc_sensor_detail_types.length < 1) {
      await this.FETCH('FETCH_PLC_SENSOR_DETAIL_TYPE', '센서 상세유형');
    }
    if (this.plc_sensor_current_logs.length < 1) {
      await this.FETCH(
        'FETCH_PLC_SENSOR_CURRENT_LOG_TO_PLC_SENSR',
        '센서현재값',
      );
    }
    this.tableSum();
    this.key = true;
  },
};
</script>
