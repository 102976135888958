<template>
  <div class="article">
    <div class="tbl_wrap" @scroll="realTimeScroll($event)">
      <button
        type="button"
        @click="scrollTop()"
        v-show="scrollHeight > 2000"
      ></button>
      <div class="tbl_option">
        <div>
          <div class="input_text">
            <label>위치 구분</label>
            <my-selectric
              v-if="locationSelectric"
              :id="'locationSelectric'"
              :watch="searchLocation"
              :options="filterLocationType"
              :commit="'setSearchLocation'"
            >
            </my-selectric>
          </div>
          <div class="input_text">
            <label>온습도계 구분</label>
            <my-selectric
              v-if="sensorTypeSelectric"
              :id="'sensorTypeSelectric'"
              :watch="searchSensorType"
              :options="filterSensorType"
              :commit="'setSearchSensorType'"
            >
            </my-selectric>
          </div>
          <div class="input_text">
            <label>온습도 구분</label>
            <my-selectric
              v-if="temphumiSelectric"
              :id="'temphumiSelectric'"
              :watch="searchSensorDetailType"
              :options="filterSensorDetailType"
              :commit="'setSearchSensorDetailType'"
            >
            </my-selectric>
          </div>
        </div>
        <div>
          <div class="input_text">
            <label>조회 날짜</label>
            <input
              type="date"
              placeholder="YYYY-MM-DD"
              :value="selectDate"
              :max="maxDate"
              @change="setDate($event)"
            />
          </div>
          <div class="input_text">
            <label>조회 시간</label>
            <input
              id="startTime"
              type="time"
              min="00:00"
              max="23:00"
              :value="startTime"
              @change="setTime($event)"
            />
            <p>~</p>
            <input
              id="endTime"
              type="time"
              min="01:00"
              max="24:00"
              :value="endTime"
              @change="setTime($event)"
            />
          </div>
          <button class="btn_sub2" @click="searchLog()">
            검색
          </button>
        </div>
        <div>
          <button class="excel_btn" @click="exportExcel">
            엑셀로 출력
          </button>
        </div>
      </div>
      <h6>조회수 : {{ filterSearchLog.length }}건</h6>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th colspan="5">
                온습도 현황
              </th>
            </tr>
            <tr>
              <th>조회시간</th>
              <th>위치</th>
              <th>구분</th>
              <th>온도(℃) / 습도(&#37;)</th>
              <th>값</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in filterSearchLog"
              :key="item.id"
              :class="{
                flow: isOverFlowValue(item) || isUnderFlowValue(item),
              }"
            >
              <td>{{ item.create_time | formatDateHHMMSS }}</td>
              <td>
                {{ item.plc_location_name }}
              </td>
              <td>
                {{ item.plc_sensor_type_name }}
              </td>
              <td>
                {{ item.plc_sensor_detail_type_name }}
              </td>
              <td>
                {{
                  item.plc_sensor_detail_type_id == 2
                    ? `${item.value}&#37;`
                    : item.value + '℃'
                }}
              </td>
            </tr>
          </tbody>
        </table>
        <infinite-loading
          v-if="pageIndex != 0"
          :key="rerend"
          @infinite="infiniteHandler"
          spinner="bubbles"
        ></infinite-loading>
      </div>
    </div>
  </div>
</template>

<script>
import XLSX from 'xlsx';
import { mapGetters } from 'vuex';
import {
  yyyymmdd,
  yyyy년MM월dd일HH시mm분ss초,
  yyyy년MM월dd일,
} from '@/utils/func';
import { formatDateHHMMSS } from '@/utils/filters';
import fetchMixin from '@/mixins/fetch';
import MySelectric from '@/layouts/components/MySelectric';
import SPINNERMIXIN from '@/mixins/spinner';

import InfiniteLoading from 'vue-infinite-loading';

export default {
  mixins: [fetchMixin, SPINNERMIXIN],
  components: {
    MySelectric,
    InfiniteLoading,
  },
  data() {
    return {
      maxDate: yyyymmdd(new Date()),
      locationSelectric: false,
      sensorTypeSelectric: false,
      temphumiSelectric: false,
      scrollChk: false,
      rerend: 0,
      scrollHeight: 0,
    };
  },
  computed: {
    ...mapGetters({
      plc_sensor_logs_time: 'getPlcSensorLogTime',

      selectDate: 'getSelectDateFromPlcSensorLog',
      startTime: 'getStartTimeFromPlcSensorLog',
      endTime: 'getEndTimeFromPlcSensorLog',

      searchLocation: 'getSearchLocation',
      searchSensorType: 'getSearchSensorType',
      searchSensorDetailType: 'getSearchSensorDetailType',
      pageIndex: 'getPageIndexFromPlcSensorLog',

      locationOptions: 'getSensorLocations',
      plc_sensors: 'getPlcSensor',
      sensorTypeOptions: 'getSensorTypes',
      sensorDetailTypeOptions: 'getSensorDetailTypes',

      scrollDate: 'getScrollDateFromPlcSensorLog',
      scrollStartTime: 'getScrollStartTimeFromPlcSensorLog',
      scrollEndTime: 'getScrollEndTimeFromPlcSensorLog',
      scrollLocation: 'getScrollLocation',
      scrollSensorType: 'getScrollSensorType',
      scrollSensorDetailType: 'getScrollSensorDetailType',
    }),
    filterLocationType() {
      let sensorLocationOptions = [];

      sensorLocationOptions.push({
        label: '위치 전체',
        value: 0,
        detail: null,
      });

      this.locationOptions.forEach(el => {
        sensorLocationOptions.push({
          label: el.name,
          value: el.id,
          detail: el.plc_location_type_id,
        });
      });
      return sensorLocationOptions;
    },
    filterSensorType() {
      let sensorTypeOptions = [];

      sensorTypeOptions.push({
        label: '온습도계 전체',
        value: 0,
        detail: null,
      });

      const type = this.sensorTypeOptions.filter(
        x => x.detail == 'TH' || x.detail == 'T',
      );

      type.forEach(el => {
        sensorTypeOptions.push({
          label: el.name,
          value: el.id,
          detail: el.detail,
        });
      });
      return sensorTypeOptions;
    },
    filterSensorDetailType() {
      let sensorDetailTypeOptions = [];

      sensorDetailTypeOptions.push({
        label: '온습도 전체',
        value: 0,
        detail: null,
      });

      if (this.searchSensorType != 0) {
        let filterDetail = this.sensorDetailTypeOptions.filter(
          x => x.plc_sensor_type_id == this.searchSensorType,
        );
        filterDetail.forEach(el => {
          sensorDetailTypeOptions.push({
            label: el.name,
            value: el.id,
            detail: el.detail,
          });
        });
      } else {
        sensorDetailTypeOptions.push({
          label: '온도',
          value: -1,
          detail: 'T',
        });
        sensorDetailTypeOptions.push({
          label: '습도',
          value: 2,
          detail: 'H',
        });
      }

      return sensorDetailTypeOptions;
    },
    filterSearchLog() {
      let searchLog = this.lodash.clonedeep(this.plc_sensor_logs_time);

      if (searchLog.length > 0) {
        searchLog.forEach(el => {
          if (
            (el.plc_sensor_name.includes('3') &&
              !el.plc_location_name.includes('3')) ||
            (el.plc_sensor_name.includes('2-2') &&
              !el.plc_location_name.includes('2-2'))
          ) {
            el.plc_sensor_type_name = el.plc_sensor_type_name + '3';
          } else if (
            el.plc_sensor_name.includes('2') &&
            !el.plc_location_name.includes('2')
          ) {
            el.plc_sensor_type_name = el.plc_sensor_type_name + '2';
          }
        });
      }
      return searchLog;
    },
  },
  methods: {
    isOverFlowValue(item) {
      const found = this.plc_sensors.find(x => x.id == item.plc_sensor_id);
      if (found != undefined) {
        if (found.max_value < item.value) {
          console.log('isOverFlowValue is True');
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    scrollTop() {
      const scrT = document.querySelector('.tbl_wrap');
      scrT.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      // this.visible = this.scrT.scrollTop > 1500 ? true : false;
    },

    realTimeScroll(e) {
      this.scrollHeight = e.target.scrollTop;
    },
    isUnderFlowValue(item) {
      const found = this.plc_sensors.find(x => x.id == item.plc_sensor_id);
      if (found != undefined) {
        if (found.min_value > item.value) {
          console.log('isUnderFlowValue is True');
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    setDate(e) {
      this.$store.commit('setSelectDateToPlcSensorLog', e.target.value);
    },
    setTime(e) {
      if (e.target.id == 'startTime') {
        this.$store.commit('setStartTimeToPlcSensorLog', e.target.value);
      } else {
        this.$store.commit('setEndTimeToPlcSensorLog', e.target.value);
      }
    },
    async searchLog() {
      this.$store.commit('resetPlcSensorLogTime');
      this.$store.commit('setPageIndexToPlcSensorLog', 0);
      this.$store.commit(
        'setScrollDateToPlcSensorLog',
        this.lodash.clonedeep(this.selectDate),
      );
      this.$store.commit(
        'setScrollStartTimeToPlcSensorLog',
        this.lodash.clonedeep(this.startTime),
      );

      this.$store.commit(
        'setScrollEndTimeToPlcSensorLog',
        this.lodash.clonedeep(this.endTime),
      );
      this.$store.commit(
        'setScrollLocation',
        this.lodash.clonedeep(this.searchLocation),
      );
      this.$store.commit(
        'setScrollSensorType',
        this.lodash.clonedeep(this.searchSensorType),
      );
      this.$store.commit(
        'setScrollSensorDetailType',
        this.lodash.clonedeep(this.searchSensorDetailType),
      );

      await this.FETCH_WITH_PAYLOAD(
        'FETCH_PLC_SENSOR_LOG_TIME',
        {
          date: this.selectDate,
          start: this.startTime,
          end: this.endTime,
          loction: this.searchLocation,
          type: this.searchSensorType,
          detailType: this.searchSensorDetailType,
          pageIndex: this.pageIndex,
        },
        '온습도현황',
      );
      this.$store.commit('setPageIndexToPlcSensorLog', 1);
    },
    async infiniteHandler($state) {
      if (this.pageIndex != 0) {
        const maxLen = 100;
        await this.$store
          .dispatch(
            'FETCH_PLC_SENSOR_LOG_TIME',
            {
              date: this.scrollDate,
              start: this.scrollStartTime,
              end: this.scrollEndTime,
              loction: this.scrollLocation,
              type: this.scrollSensorType,
              detailType: this.scrollSensorDetailType,
              pageIndex: this.pageIndex,
            },
            '온습도현황',
          )
          .then(response => {
            setTimeout(() => {
              console.log('@@', response);
              this.$store.commit(
                'setPageIndexToPlcSensorLog',
                this.pageIndex + 1,
              );
              if (response.data.data_list.length) {
                $state.loaded();
                // 끝 지정(No more data) - 데이터가 maxLen개 미만이면
                if (response.data.data_list.length / maxLen < 1) {
                  $state.complete();
                }
              } else {
                // 끝 지정(No more data)
                $state.complete();
              }
            }, 1000);
          })
          .catch(error => {
            console.log(error);
            $state.complete();
          });
      }
    },
    exportExcel() {
      this.showSpinner();
      this.$store
        .dispatch('FETCH_PLC_SENSOR_LOG_TIME_EXCEL', {
          date: this.selectDate,
          start: this.startTime,
          end: this.endTime,
          loction: this.searchLocation,
          type: this.searchSensorType,
          detailType: this.searchSensorDetailType,
        })
        .then(response => {
          const plcData = response.data.data_list;
          let plcList = [];

          let merge = [
            { s: { r: 0, c: 1 }, e: { r: 0, c: 3 } },
            { s: { r: 1, c: 1 }, e: { r: 1, c: 3 } },
          ];
          let standardInfo = [
            ['기준시간', yyyy년MM월dd일HH시mm분ss초(new Date())],
            [
              '조회 시간',
              yyyy년MM월dd일(this.selectDate) +
                ' (' +
                this.startTime +
                ' ~ ' +
                this.endTime +
                ')',
            ],
            [],
            ['위치 구분', this.filterLocationType[this.searchLocation].label],
            [
              '온습도계 구분',
              this.filterSensorType[this.searchSensorType].label,
            ],
            [
              '온습도 구분',
              this.filterSensorDetailType[this.searchSensorDetailType].label,
            ],
            [],
            [],
            ['조회시간', '위치', '구분', '온도(℃) / 습도(%)', '값'],
          ];

          plcList = plcList.concat(standardInfo);

          plcData.forEach(el => {
            if (
              (el.plc_sensor_name.includes('3') &&
                !el.plc_location_name.includes('3')) ||
              (el.plc_sensor_name.includes('2-2') &&
                !el.plc_location_name.includes('2-2'))
            ) {
              el.plc_sensor_type_name = el.plc_sensor_type_name + '3';
            } else if (
              el.plc_sensor_name.includes('2') &&
              !el.plc_location_name.includes('2')
            ) {
              el.plc_sensor_type_name = el.plc_sensor_type_name + '2';
            }

            plcList.push([
              formatDateHHMMSS(el.create_time),
              el.plc_location_name,
              el.plc_sensor_type_name,
              el.plc_sensor_detail_type_name,
              el.plc_sensor_detail_type_id == 2
                ? el.value + '%'
                : el.value + '℃',
            ]);
          });

          let wb = XLSX.utils.book_new();

          let plcDataTab = XLSX.utils.json_to_sheet(plcList, {
            skipHeader: true,
          });

          plcDataTab['!merges'] = merge;

          XLSX.utils.book_append_sheet(wb, plcDataTab, '온습도현황');

          let title =
            '온습도현황 - ' +
            this.filterLocationType[this.searchLocation].label +
            '(' +
            yyyy년MM월dd일(this.selectDate) +
            '_' +
            this.startTime +
            '-' +
            this.endTime +
            ').xls';

          XLSX.writeFile(wb, title);
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
  },
  async created() {
    if (this.selectDate == null) {
      let hours = new Date().getHours();
      console.log('확인', hours);
      let hoursString = String(hours > 9 ? hours : `0${hours}`);
      let minutes = new Date().getMinutes();
      minutes = String(minutes > 9 ? minutes : `0${minutes}`);

      let endTime = hoursString + ':' + minutes;
      this.$store.commit('setEndTimeToPlcSensorLog', endTime);

      hoursString = String(hours - 1 > 9 ? hours - 1 : `0${hours - 1}`);
      let startTime = hoursString + ':' + minutes;
      this.$store.commit('setStartTimeToPlcSensorLog', startTime);
      this.$store.commit('setSelectDateToPlcSensorLog', this.maxDate);
    }

    if (this.locationOptions.length < 2)
      await this.FETCH('FETCH_PLC_SENSOR_LOCATION', 'PLC 위치');

    if (this.sensorTypeOptions.length < 2)
      await this.FETCH('FETCH_PLC_SENSOR_TYPE', 'PLC 타입');

    if (this.sensorDetailTypeOptions.length < 2)
      await this.FETCH('FETCH_PLC_SENSOR_DETAIL_TYPE', 'PLC 상세타입');

    if (this.plc_sensors.length < 1) {
      await this.FETCH('FETCH_PLC_SENSOR', 'PLC 센서');
    }
    this.locationSelectric = true;
    this.sensorTypeSelectric = true;
    this.temphumiSelectric = true;
  },
};
</script>

<style lang="scss" scoped></style>
