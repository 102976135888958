<template>
  <div class="article" :class="{ active: fullScreen }">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <span>조회시간: {{ str_time }}</span>

        <button class="btn_sub1" @click="fullScreen = !fullScreen">
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
        <h5 class="title">제습기 현황</h5>
      </div>
      <div class="humi_wrap" v-for="item in dehumiLogs" :key="item.id">
        <h5>{{ item[0].plc_sensor_name.split('-')[0] }}</h5>
        <div
          class="humi_image"
          :class="
            item.find(x => x.plc_sensor_detail_type_id == 8).value == 0 ||
            item.find(x => x.plc_sensor_detail_type_id == 8).value == null
              ? 'off'
              : 'on'
          "
        ></div>
        <ul>
          <li>
            동작상태 :
            <span
              :class="
                ({
                  off:
                    item.find(x => x.plc_sensor_detail_type_id == 8).value == 0,
                },
                `on_0${$makeNumber($decimalMul(item[0].value, 100))}`)
              "
              >{{
                item.find(x => x.plc_sensor_detail_type_id == 8).action
              }}</span
            >
          </li>
          <li>
            현재습도 :
            {{ item.find(x => x.plc_sensor_name.includes('현재')).value }}
            %
          </li>
          <li>
            설정습도 :
            {{ item.find(x => x.plc_sensor_name.includes('설정')).value }}
            %
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import FETCH_MIXIN from '@/mixins/fetch';
import { yyyy년MM월dd일HH시mm분ss초 } from '@/utils/func';
export default {
  mixins: [FETCH_MIXIN],
  data() {
    return {
      fullScreen: false,
      str_time: yyyy년MM월dd일HH시mm분ss초(new Date()),
      timerCount: 10,
      timerMax: 10,
      interval: null,
      timeInterval: null,
      currentTimeInterval: null,
    };
  },
  computed: {
    ...mapGetters({
      plc_sensor_logs: 'getPlcSensorLog',
      plc_tempHumi_logs: 'getTemperatureHumiditySensorLog',
      tabIndex: 'getOpenTabIndexFromPlcMonitorPage',
    }),
    dehumiLogs() {
      if (
        this.plc_sensor_logs != undefined &&
        this.plc_sensor_logs.length > 0
      ) {
        let clone = this.lodash
          .clonedeep(this.plc_sensor_logs)
          .filter(
            x =>
              x.plc_sensor_name.includes(`제습기`) &&
              x.plc_sensor_detail_type_id != 1,
          )
          .sort((a, b) => b.plc_sensor_id - a.plc_sensor_id);
        let arr = [];
        clone.forEach((element, index) => {
          if (element.plc_sensor_detail_type_id == 8) {
            switch (this.$makeNumber(this.$decimalMul(element.value, 100))) {
              case 0:
                element.action = '동작정지';
                break;
              case 2:
                element.action = '난방중';
                break;
              case 3:
                element.action = '냉방중';
                break;
              case 4:
                element.action = '제습중';
                break;
              case 5:
                element.action = '송풍중';
                break;
              case 6:
                element.action = '청정';
                break;
              case 7:
                element.action = '자동';
                break;
              case 8:
                element.action = '가습중';
                break;
              case null:
                element.action = '동작정지';
            }
          }
          if (index % 3 == 0) {
            arr.push(clone.slice(index, index + 3));
          }
        });
        return arr;
      } else {
        return [];
      }
    },
  },
  methods: {
    // async refreshData() {
    //   await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '온습도현황');
    //   this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
    // },
  },
  destroyed() {
    console.log('destroyed');
    clearInterval(this.interval);
    clearInterval(this.timeInterval);
    clearInterval(this.currentTimeInterval);
  },
  async created() {
    this.timerCount = this.timerMax;
    await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '온습도현황');

    this.interval = setInterval(async () => {
      this.timerCount = this.timerMax;

      if (this.tabIndex == 4) {
        await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '온습도현황');
      }
      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(() => {
        this.timerCount -= 1;
      }, 1000);
    }, this.timerMax * 1000);
    this.timeInterval = setInterval(() => {
      this.timerCount -= 1;
    }, 1000);
    this.currentTimeInterval = setInterval(() => {
      this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
    }, 1000);
  },
};
</script>

<style lang="scss" scoped></style>
