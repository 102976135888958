<template>
  <div
    id="contents"
    :class="{
      status_board: tabIndex == 0 || tabIndex == 1,
      plc_monitor: tabIndex == 0 || tabIndex == 1,
      plc_th_status: tabIndex == 0,
      plc_metal_status: tabIndex == 1,
      plc_th_log: tabIndex == 2,
      plc_metal_log: tabIndex == 3,
      dehumidifier_status: tabIndex == 4,
      default_setting: tabIndex == 5,
    }"
  >
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToPlcMonitorPage'"
          :options="[
            { title: '실시간 온습도 현황', name: 'real_time_status' },
            { title: '실시간 금속검출 현황', name: 'counter_time_status' },
            { title: '온습도 현황', name: 'plc_th_status' },
            { title: '금속검출 현황', name: 'plc_metal_detection_status' },
            { title: '제습기 현황', name: 'dehumidifier_status' },
            { title: '기준값 설정', name: 'default_setting' },
          ]"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li :class="{ active: tabIndex == 0 }">
            <a @click="SetOpenTabIndex(0)">실시간 온습도 현황</a>
          </li>
          <li :class="{ active: tabIndex == 1 }">
            <a @click="SetOpenTabIndex(1)">실시간 금속검출 현황</a>
          </li>
          <li :class="{ active: tabIndex == 2 }">
            <a @click="SetOpenTabIndex(2)">온습도 현황</a>
          </li>
          <li :class="{ active: tabIndex == 3 }">
            <a @click="SetOpenTabIndex(3)">금속검출 현황</a>
          </li>
          <li :class="{ active: tabIndex == 4 }">
            <a @click="SetOpenTabIndex(4)">제습기 현황</a>
          </li>
          <li :class="{ active: tabIndex == 5 }">
            <a @click="SetOpenTabIndex(5)">기준값 설정</a>
          </li>
        </ul>
      </div>
      <temp-realtime-form v-if="tabIndex == 0"></temp-realtime-form>
      <counter-realtime-form v-if="tabIndex == 1"></counter-realtime-form>
      <temp-log-form v-if="tabIndex == 2"></temp-log-form>
      <counter-log-form v-if="tabIndex == 3"></counter-log-form>
      <dehumidifier-status-form v-if="tabIndex == 4"></dehumidifier-status-form>
      <default-plc-form v-if="tabIndex == 5"></default-plc-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import TempRealtimeForm from '@/views/forms/Custom/Monitor/12/TempRealtimeForm';
import CounterRealtimeForm from '@/views/forms/Custom/Monitor/12/CounterRealtimeForm';
import TempLogForm from '@/views/forms/Custom/Monitor/12/TempLogForm';
import CounterLogForm from '@/views/forms/Custom/Monitor/12/CounterLogForm';
import DehumidifierStatusForm from '@/views/forms/Custom/Monitor/12/DehumidifierStatusForm';
import DefaultPlcForm from '@/views/forms/Custom/Monitor/12/DefaultPlcForm';
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import FETCH_MIXIN from '@/mixins/fetch';

export default {
  mixins: [ClosePageMixin, FavoriteMixin, FETCH_MIXIN],
  components: {
    AsideSelectric,
    TempRealtimeForm,
    CounterRealtimeForm,
    TempLogForm,
    CounterLogForm,
    DehumidifierStatusForm,
    DefaultPlcForm,
  },
  computed: {
    ...mapGetters({
      tabIndex: 'getOpenTabIndexFromPlcMonitorPage',
    }),
  },
  methods: {
    ...mapMutations({
      SetOpenTabIndex: 'setOpenTabIndexToPlcMonitorPage',
    }),
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitPlcMonitorPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style lang="scss">
@import 'cheongsaem_monitoring';
</style>
